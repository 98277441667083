import Resizer from "react-image-file-resizer";
import CNPJ from "./cnpj";
import CPF from "./cpf";

export const generateFirstDigits = (): string => {
  let digits = "";
  for (let i = 0; i < 9; ++i) {
    digits += String(Math.floor(Math.random() * 10));
  }

  return digits;
};

export const hasCPFLength = (cpf: string): void | boolean => {
  if (cpf.length > 11 || cpf.length < 11) {
    return false;
  }

  return true;
};

export const formatCPF = (cpf: string, format?: boolean): string => {
  let digitsSeparator = "";
  let checkersSeparator = "";

  if (format) {
    digitsSeparator = ".";
    checkersSeparator = "-";
  }

  return (
    cpf.slice(0, 3) +
    digitsSeparator +
    cpf.slice(3, 6) +
    digitsSeparator +
    cpf.slice(6, 9) +
    checkersSeparator +
    cpf.slice(9, 11)
  );
};

export const formatCNPJ = (cnpj: string | number): string => {
  return cnpj
    .toString()
    .replace(/[^\d]/g, "")
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
};

export const digit = (numbers: string): number => {
  let index = 2;

  const sum = [...numbers].reverse().reduce((buffer, number) => {
    buffer += Number(number) * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);

  const mod = sum % 11;

  return mod < 2 ? 0 : 11 - mod;
};

export function isDate(stringData: string) {
  const regExpCaracter = /[^\d]/;
  const regExpEspaco = /^\s+|\s+$/g;
  if (stringData.length != 10) return false;
  const splitData = stringData.split("/");
  if (splitData.length != 3) return false;
  splitData[0] = splitData[0].replace(regExpEspaco, "");
  splitData[1] = splitData[1].replace(regExpEspaco, "");
  splitData[2] = splitData[2].replace(regExpEspaco, "");
  if (
    splitData[0].length != 2 ||
    splitData[1].length != 2 ||
    splitData[2].length != 4
  )
    return false;
  if (
    regExpCaracter.test(splitData[0]) ||
    regExpCaracter.test(splitData[1]) ||
    regExpCaracter.test(splitData[2])
  ) {
    return false;
  }
  const dia = parseInt(splitData[0], 10);
  const mes = parseInt(splitData[1], 10) - 1;
  const ano = parseInt(splitData[2], 10);
  const novaData = new Date(ano, mes, dia);
  if (
    novaData.getDate() != dia ||
    novaData.getMonth() != mes ||
    novaData.getFullYear() != ano
  ) {
    return false;
  } else {
    return true;
  }
}

export function isAdult(data: string) {
  const setDate = new Date(
    parseInt(data.split("/")[2]) + 18,
    parseInt(data.split("/")[1]) - 1,
    parseInt(data.split("/")[0]),
  );
  const currdate = new Date();
  if (currdate < setDate) {
    return false;
  } else {
    return true;
  }
}

export function validateBirthDate(value: string) {
  if (!isDate(value)) return "Digite um data de nascimento válida!";
  if (!isAdult(value)) return "Cadastro apenas para maiores de 18 anos!";
  return true;
}

export const validatePromotionDate = (initialDateString: string, finalDateString: string, errorMessage?: string) => (currentDateString: string) => {
  if (!isDate(initialDateString)) return "Informe uma data inicial de promoção válida!";

  if (!isDate(finalDateString)) return "Informe uma data final de promoção válida!";

  if (!isDate(currentDateString)) return "Informe uma data válida!";

  const initialDate = new Date(
    parseInt(initialDateString.split("/")[2]),
    parseInt(initialDateString.split("/")[1]) - 1,
    parseInt(initialDateString.split("/")[0]),
  );

  const finalDate = new Date(
    parseInt(finalDateString.split("/")[2]),
    parseInt(finalDateString.split("/")[1]) - 1,
    parseInt(finalDateString.split("/")[0]),
  );

  const currentDate = new Date(
    parseInt(currentDateString.split("/")[2]),
    parseInt(currentDateString.split("/")[1]) - 1,
    parseInt(currentDateString.split("/")[0]),
  );

  if (currentDate < initialDate || currentDate > finalDate) {
    return errorMessage ?? "Data de promoção inválida!";
  }

  return true;
}

const allCharsAreEqual = (value: string) => {
  for (let i = 0; i < value.length + 1; i++) {
    if (value === Array.from({ length: value.length + 1 }).join(value[i])) {
      return true;
    }
  }

  return false;
}

export const allDigitsAreEqual = (value: string) => {
  for (let i = 0; i < value.length; i++) {
    if (value === Array.from({ length: value.length + 1 }).join(String(i))) {
      return true;
    }
  }

  return false;
}

export const validateName = (value: string) => {
  if (value.length < 3) return "Informe um nome válido!";
  if (allCharsAreEqual(value)) return "Informe um nome válido!";

  return true;
}

export const validatePhone = (value: string) => {
  const phone = value.replace(/\D/g, "");

  if (phone.length < 11) return "Informe um telefone válido!";
  if (allDigitsAreEqual(phone)) return "Informe um telefone válido!";

  return true;
}

export const validateMail = (mail: string) => {
  if (!mail.includes("@") || !mail.includes(".")) return "Informe um e-mail válido!";
  return true;
}

export const validatePromotionValue = (promotionValue: number, errorMessage?: string) => (currentValue: string) => {
  let strValue = currentValue.replace(/\D/g, "").replace(/^0+/, "");
  strValue = strValue.padStart(3, "0").replace(/(\d{2})$/, ".$1");
  
  const value = Number(strValue);

  if (value < promotionValue) {
    return errorMessage ?? "Valor de compra menor que o valor da promoção!";
  }

  return true;
};

export function validateCPF(cpf: string) {
  if (!CPF.validate(cpf)) return "Informe um CPF válido!";
  return true;
}

export function validateCNPJ(cnpj: string) {
  if (!CNPJ.validate(cnpj)) return "Informe um CNPJ válido!";
  return true;
}

export function matchPasswords(value1: string) {
  return function (value2: string) {
    if (value1 !== value2) return "As senhas não conferem!";
    return true;
  };
}

export const resizeFile = (file: File) =>
  new Promise<string>((resolve) => {
    const extension = file.type.split("/")[1].toUpperCase();

    Resizer.imageFileResizer(
      file,
      800,
      800,
      extension,
      100,
      0,
      (uri: any) => {
        resolve(uri);
      },
      "base64",
    );
  });
